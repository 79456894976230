<template>
  <ActionModal
    v-if="isModalShown"
    :width="'440px'"
    :height="'390px'"
    :isModalShown="isModalShown"
    :isLoading="isModalLoading"
  >
    <div class="modal-header">
      <div class="modal-title">Please choose a status and add a value</div>
      <div class="close-modal-btn" @click="$emit('hideModal')"></div>
    </div>
    <div class="modal-error">{{modalError}}</div>
    <div class="modal-content">
      <DropdownMenu
        :title="'Status'"
        :currentValue="newProspectStatus"
        :isDropdownOpened="isStatusDropdownOpened"
        :optionsObj="prospectStatus"
        :menuHeight="'196px'"
        @openDropdown="openStatusDropdown"
        @setNewValue="$emit('setNewProspectStatus', $event)"
        @closeDropdown="closeStatusDropdown"
      />
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :lable="'Value'"
        :placeholder="'Enter deal value'"
        :value="newDealValue"
        :isDisabled="newProspectStatus === 'Not in Pipeline'"
        @updateValue="$emit('setNewDealValue', $event)"
      />
    </div>
    <ActionButton
      :width="'350px'"
      :height="'54px'"
      :text="'Move'"
      @click="$emit('moveToProspects')"
    />
  </ActionModal>
</template>

<script>
import ActionModal from '../../../common/ActionModal.vue'
import DropdownMenu from '../../../common/DropdownMenu.vue'
import CommonInputField from '../../../common/CommonInputField.vue'
import ActionButton from '../../../common/ActionButton.vue'

import { ProspectStatus } from '../../../../utils/constants'

export default {
  props: [
    'isModalShown',
    'isModalLoading',
    'newProspectStatus',
    'newDealValue',
    'modalError',
  ],
  components: {
    ActionModal,
    DropdownMenu,
    CommonInputField,
    ActionButton,
  },
  data() {
    return {
      prospectStatus: ProspectStatus,
      isStatusDropdownOpened: false,
    }
  },
  methods: {
    openStatusDropdown() {
      this.isStatusDropdownOpened = true
    },
    closeStatusDropdown() {
      this.isStatusDropdownOpened = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

.modal-header {
  width: calc(100% - 80px);
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title {
    font-size: $fontSizeModalTitle;
  }

  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/close-icon.svg');
    cursor: pointer;
  }
}

.modal-error {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $redWarningColor;
  font-size: $fontSizeMinified;
}

.modal-content {
  width: calc(100% - 80px);
  height: 156px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>