<template>
  <div class="info-box-container">
    <img
      :src="iconSrc"
      alt="Info Icon"
      class="info-icon"
      @mouseenter="showInfoBox"
      @mouseleave="hideInfoBox"
    />
    <div v-if="showInfo" class="info-box">
      <p class="text" v-html="text"></p>
    </div>
  </div>
</template>

<script>
import InfoBadge from '../../assets/icons/infobox.svg'

export default {
  name: 'InfoBox',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showInfo: false,
      iconSrc: InfoBadge,
    }
  },
  methods: {
    showInfoBox() {
      this.showInfo = true
    },
    hideInfoBox() {
      this.showInfo = false
    },
  },
}
</script>

<style scoped lang="scss">
.info-icon {
  width: 16px;
  height: 16px;
  margin-bottom: 8px;
  cursor: pointer;
}

.info-box {
  width: 200px;
  position: absolute;
  right: -220px;
  top: 0;
  background: white;
  border: 1px solid #c9cde8;
  padding: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  height: fit-content;
  border-radius: 8px;
  display: flex;
  p {
    margin: 0;
  }
}

.info-box-container {
  display: inline-block;
  position: relative;
}

.text {
  font-size: 12px;
  line-height: 14px;
}
</style>
