<template>
  <MainCard>
    <MainCardHeader>
      <div class="card-header-row">
        <div class="card-title">
          Clients: Not In Pipeline
          <InfoBox
            text="Customers with recurring business but do <b>not</b> expect any sales within the next 30 days. No value is added to your pipeline."
          />
        </div>
        <SearchField
          @search="handleSearch($event)"
          @cancelSearch="handleSearch($event)"
        />
      </div>
      <CardTableHeader
        :isCheckboxShown="true"
        :isCompanyShown="true"
        :selectedRows="selectedRows"
        :isSelectAllCheckbox="isSelectAllCheckbox"
        :order="order"
        @checkboxSelect="selectAllRows"
        @removeSelection="removeRowsSelection"
        @setOrder="setOrder"
      />
    </MainCardHeader>
    <DividerLine />
    <div
      class="clients-list"
      v-if="currentPage.length && !errorMessage && !isLoading"
    >
      <draggable
        v-model="currentPage"
        handle=".drag-handle"
        ghost-class="ghost"
        :disabled="order !== '' || isDragDisabled"
        @end="handleDragEnd"
        item-key="id"
      >
        <template #item="{ element }">
          <CardRow
            :key="element.id"
            :id="element.id"
            :order="order"
            :isCheckboxShown="true"
            :isCompanyEditable="true"
            :isNameEditable="true"
            :name="element.name"
            :company="element.company"
            :selectedRows="selectedRows"
            :menuActions="Object.values(menuActions)"
            :actionMenuWidth="'282px'"
            :actionMenuHeight="'147px'"
            :actionMenuTop="'45px'"
            :isSubscriptionActive="isSubscriptionActive"
            @updateName="handleClientUpdate({ name: $event }, element)"
            @updateCompany="handleClientUpdate({ company: $event }, element)"
            @checkboxSelect="selectOneRow(element.id)"
            @removeSelection="removeOneRowSelection(element.id)"
            @actionMenuItemClick="handleActionMenuClick($event, element.id)"
          />
        </template>
      </draggable>
    </div>
    <div class="error-message" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <Spinner v-if="isLoading" />
    <DividerLine />
    <CardFooter
      :currentPageNumber="currentPageNumber"
      :totalPages="toPages"
      :currentItemsCount="`${fromItems}-${toItems}`"
      :totalItemsCount="totalItemsCount"
      :RowsPerPage="RowsPerPage"
      @setCurrentPerPage="setCurrentPerPage($event)"
      @showPrevPage="getPrevPage"
      @showNextPage="getNextPage"
    />
  </MainCard>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import draggable from 'vuedraggable'

import MainCard from '../../../common/mainContent/MainCard.vue'
import CardTableHeader from '../../../common/mainContent/CardTableHeader.vue'
import DividerLine from '../../../common/DividerLine.vue'
import InfoBox from '../../../common/InfoBox.vue'
import MainCardHeader from '../../../common/mainContent/MainCardHeader.vue'
import SearchField from '../../../common/header/SearchField.vue'
import CardRow from '../../../common/mainContent/CardRow.vue'
import CardFooter from '../../../common/mainContent/CardFooter.vue'
import MoreActionsBtn from '../../../common/MoreActionsBtn.vue'
import ActionButton from '../../../common/ActionButton.vue'
import ActionMenu from '../../../common/actionMenu/ActionMenu.vue'
import ActionMenuItem from '../../../common/actionMenu/ActionMenuItem.vue'
import Spinner from '../../../common/Spinner.vue'

import { getCurrentDate } from '../../../../utils/helpers'
import {
  UserSubscriptionStatus,
  CustomerPipelineStatus,
  CustomerPipelineStatusCode,
} from '../../../../utils/constants'

export default {
  props: ['shouldUpdateClientsList', 'selectedRows'],
  components: {
    draggable,
    MainCard,
    CardTableHeader,
    DividerLine,
    MainCardHeader,
    SearchField,
    CardRow,
    CardFooter,
    MoreActionsBtn,
    ActionButton,
    ActionMenu,
    ActionMenuItem,
    Spinner,
    InfoBox,
  },
  emits: [
    'updateClientDealsList',
    'clientsListWasUpdated',
    'moveClientToProspect',
    'selectOneSubject',
    'removeOneSubjectSelection',
    'selectAllSubjects',
    'removeAllSubjectsSelection',
  ],
  data() {
    return {
      isLoading: true,
      pageSize: 'All',
      currentPageNumber: 1,
      searchTerm: '',
      order: '',
      isDragDisabled: false,
      errorMessage: '',
      newClientCompany: '',
      isSelectAllCheckbox: false,
      menuActions: {
        ADD_TO_PIPELINE: 'Add to pipeline',
        MOVE_TO_PROSPECTS: 'Move to prospects',
        ARCHIVE: 'Archive',
      },
      activeSubscriptionStatuses: [
        UserSubscriptionStatus.Granted,
        UserSubscriptionStatus.Active,
        UserSubscriptionStatus['Granted Until'],
        UserSubscriptionStatus.Canceled,
      ],
      RowsPerPage: ['All', 10, 20, 50],
      customerPipelineStatus: CustomerPipelineStatus,
      customerPipelineStatusCode: CustomerPipelineStatusCode,
    }
  },
  async created() {
    await this.getFirstPage()
  },
  computed: {
    ...mapState(['currentUser', 'clients', 'isUndoStack', 'isRedoStack']),
    isSubscriptionActive() {
      return this.activeSubscriptionStatuses.includes(
        this.currentUser.subscription.status
      )
    },
    clientsList() {
      return this.clients.clientsList
    },
    currentPage: {
      get() {
        if (this.pageSize === 'All') return this.clientsList

        const start =
          this.currentPageNumber === 1
            ? 0
            : this.currentPageNumber * this.pageSize - this.pageSize

        const stop =
          this.currentPageNumber === 1
            ? this.pageSize
            : this.currentPageNumber * this.pageSize

        const page = this.clientsList.slice(start, stop)

        return page
      },
      set(value) {},
    },
    isUndoStackActive() {
      return this.isUndoStack
    },
    isRedoStackActive() {
      return this.isRedoStack
    },
    totalItemsCount() {
      return this.clients.clientsCount
    },
    lastId() {
      return this.currentPage.length ? this.currentPage.pop().id : 0
    },
    pageItemsBase() {
      return this.pageSize * this.currentPageNumber
    },
    fromItems() {
      if (this.pageSize === 'All') return 1

      return this.totalItemsCount === 0
        ? 0
        : this.pageItemsBase + 1 - this.pageSize
    },
    toPages() {
      if (this.pageSize === 'All') return 1
      if (this.totalItemsCount < this.pageSize) return 1
      return Math.ceil(this.totalItemsCount / this.pageSize)
    },
    toItems() {
      if (this.pageSize === 'All') return this.clientsList.length

      return this.pageItemsBase < this.totalItemsCount
        ? this.pageItemsBase
        : this.totalItemsCount
    },
  },
  watch: {
    async shouldUpdateClientsList(newValue, oldValue) {
      if (newValue) {
        this.isLoading = true
        this.pageSize = 'All'
        this.currentPageNumber = 1
        this.searchTerm = ''
        this.order = ''

        await this.clearClients()
        await this.getFirstPage()

        this.$emit('clientsListWasUpdated')
      }
    },
    modalError(newValue, oldValue) {
      if (newValue) {
        setTimeout(() => {
          this.modalError = ''
        }, 3000)
      }
    },
    async order() {
      this.isLoading = true
      this.currentPageNumber = 1

      await this.clearClients()
      await this.getFirstPage()
    },
  },
  methods: {
    ...mapActions([
      'getNextClientsPage',
      'updateClientsOrder',
      'createClient',
      'updateClient',
      'moveClientsToProspects',
      'archiveClients',
      'clearClients',
      'addDeals',
      'removeClientDeals',
      'handelUndoRedoClick',
      'searchClients',
      'changeClientPipelineStatus',
    ]),
    getCurrentDate,
    handleGeneralError(error) {
      if (
        error.response.data.detail ===
        'Deals for this subject already in pipeline'
      ) {
        this.pipelineErrorMessage = 'This item is already in pipeline'
        this.isModalErrorShown = true

        return
      }

      this.errorMessage = 'Something went wrong, please try again'
    },
    async getFirstPage() {
      try {
        await this.getNextClientsPage({
          pageSize: this.pageSize,
          pageNumber: this.currentPageNumber,
          order: this.order,
        })
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    async setCurrentPerPage(rowperPage) {
      this.currentPageNumber = 1
      this.pageSize = rowperPage
      await this.clearClients()
      await this.getFirstPage()
    },
    setOrder(evt) {
      if (this.isLoading) return
      this.order = evt
    },
    async handleDragEnd(evt) {
      this.isDragDisabled = true

      const { oldIndex, newIndex } = evt

      if (oldIndex === newIndex) return

      const elementId = this.currentPage[oldIndex].id

      try {
        await this.updateClientsOrder({
          elementId,
          oldLocalIndex: oldIndex,
          newLocalIndex: newIndex,
        })
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isDragDisabled = false
      }
    },
    async getNextPage() {
      if (
        (this.currentPageNumber !== 0 &&
          this.toItems === this.totalItemsCount) ||
        this.isLoading
      )
        return

      this.currentPageNumber += 1
      this.removeRowsSelection()

      if (this.currentPage.length) return

      this.isLoading = true

      try {
        await this.getNextClientsPage({
          pageSize: this.pageSize,
          pageNumber: this.currentPageNumber,
          searchTerm: this.searchTerm,
          order: this.order,
        })
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    getPrevPage() {
      if (this.currentPageNumber === 1) return

      this.removeRowsSelection()
      this.currentPageNumber -= 1
    },
    selectAllRows() {
      const ids = this.currentPage.map((item) => item.id)
      this.$emit('selectAllSubjects', ids)
    },
    removeRowsSelection() {
      this.$emit('removeAllSubjectsSelection')
    },
    async handleSearch(searchValue) {
      this.isLoading = true
      this.searchTerm = searchValue
      this.currentPageNumber = 1

      const payload = {
        order: this.order,
        searchTerm: searchValue,
        pageSize: this.pageSize,
        pageNumber: this.currentPageNumber,
      }

      try {
        await this.searchClients(payload)
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    selectOneRow(rowId) {
      this.$emit('selectOneSubject', rowId)

      if (this.selectedRows.length === this.currentPage.length) {
        this.isSelectAllCheckbox = true
      }
    },
    removeOneRowSelection(rowId) {
      this.$emit('removeOneSubjectSelection', rowId)
      this.isSelectAllCheckbox = false
    },
    handleClientUpdate(partialClientUpdate, client) {
      const updatedClient = { ...client, ...partialClientUpdate }
      this.updateClient(updatedClient)
    },
    async handleActionMenuClick(action, clientId = null) {
      this.isActionMenuShown = false
      this.isLoading = true

      const clientIds = clientId ? [clientId] : this.selectedRows

      if (!clientIds.length) {
        this.isLoading = false
        return
      }

      try {
        if (action === this.menuActions.ADD_TO_PIPELINE) {
          await this.addDeals({ subject_ids: clientIds })
          this.$emit('updateClientDealsList')
        }

        if (action === this.menuActions.MOVE_TO_PROSPECTS) {
          this.$emit('moveClientToProspect', clientId)
          return
        }

        if (action === this.menuActions.ARCHIVE) {
          await this.archiveClients(clientIds)
        }

        this.currentPageNumber = 1

        await this.clearClients()
        await this.getNextClientsPage({
          pageSize: this.pageSize,
          pageNumber: this.currentPageNumber,
          order: this.order,
        })

        this.removeRowsSelection()
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    async switchClientPipelineStatus(clientId, isClientInPipeline) {
      this.isLoading = true

      try {
        await this.changeClientPipelineStatus({ clientId, isClientInPipeline })

        this.currentPageNumber = 1

        await this.clearClients()
        await this.getNextClientsPage({
          pageSize: this.pageSize,
          pageNumber: this.currentPageNumber,
          order: this.order,
        })

        this.removeRowsSelection()

        this.$emit('updateClientDealsList')
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
  },
  async beforeUnmount() {
    await this.clearClients()
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

.main-card {
  height: 655px;
  min-height: 655px;
  margin-top: 20px !important;

  .card-header-row {
    width: calc(100% - 80px);
    height: calc(100% - 47px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card-title {
      font-size: $fontSizeTitle;
    }
  }
}

@media (max-width: 1024px) {
  .card-header-row {
    align-items: center;
  }
}

@media (max-width: 420px) {
  .card-table-header {
    width: calc(100% - 20px);
  }

  .action-button {
    width: 78px !important;
    font-size: $fontSizeActionButtonMinified;
    padding-left: 5px;
  }
}
</style>

<style lang="scss">
@import '../../../../assets/styleVars.scss';

.clients-list {
  width: 100%;
  height: calc(100% - 204px);
  overflow-y: scroll;

  .ghost {
    opacity: 0.9;
    background: $appActionColor;
  }
}

.error-message {
  width: 100%;
  height: calc(100% - 104px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $redWarningColor;
}
</style>
