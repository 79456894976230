<template>
  <ActionModal
    v-if="isModalShown"
    :width="'440px'"
    :height="'524px'"
    :isModalShown="isModalShown"
    :isLoading="isModalLoading"
  >
    <div class="modal-header">
      <div class="modal-title">Add new client</div>
      <div class="close-modal-btn" @click="$emit('hideModal')"></div>
    </div>
    <div class="modal-error">{{modalError}}</div>
    <div class="modal-content">
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :lable="'Contact Name'"
        :placeholder="'Enter name'"
        :value="newClientName"
        @updateValue="$emit('setNewClientName', $event)"
      />
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :lable="'Company'"
        :placeholder="'Enter company'"
        :value="newClientCompany"
        @updateValue="$emit('setNewClientCompany', $event)"
      />
      <DropdownMenu
        :title="'Pipeline Status'"
        :currentValue="newClientPipelineStatus"
        :isDropdownOpened="isPipelineStatusDropdownOpened"
        :optionsObj="pipelineStatuses"
        :menuHeight="'96px'"
        @openDropdown="openPipelineStatusDropdown"
        @setNewValue="$emit('setNewClientPipelineStatus', $event)"
        @closeDropdown="closePipelineStatusDropdown"
      />
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :lable="'Value'"
        :placeholder="'Enter deal value'"
        :value="newDealValue"
        :isDisabled="newClientPipelineStatus === 'Not in Pipeline'"
        @updateValue="$emit('setNewDealValue', $event)"
      />
      <ActionButton
        :width="'350px'"
        :height="'54px'"
        :text="'Add new client'"
        @click="$emit('createClient')"
      />
    </div>
  </ActionModal>
</template>

<script>
import ActionModal from './ActionModal.vue'
import ActionButton from './ActionButton.vue'
import CommonInputField from './CommonInputField.vue'
import DropdownMenu from '../common/DropdownMenu.vue'

export default {
  props: [
    'isModalShown',
    'isModalLoading',
    'modalError',
    'newClientName',
    'newClientCompany',
    'newClientPipelineStatus',
    'newDealValue',
    'pipelineStatuses',
  ],
  components: {
    ActionModal,
    ActionButton,
    CommonInputField,
    DropdownMenu,
  },
  data() {
    return {
      isPipelineStatusDropdownOpened: false,
    }
  },
  methods: {
    openPipelineStatusDropdown() {
      this.isPipelineStatusDropdownOpened = true
    },
    closePipelineStatusDropdown() {
      this.isPipelineStatusDropdownOpened = false
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styleVars.scss';

.modal-header {
  width: calc(100% - 80px);
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title {
    font-size: $fontSizeModalTitle;
  }

  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/icons/close-icon.svg');
    cursor: pointer;
  }
}

.modal-error {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $redWarningColor;
  font-size: $fontSizeMinified;
}

.modal-content {
  width: calc(100% - 80px);
  height: 384px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
</style>